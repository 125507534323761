import React from 'react'
import StatsForm from "../components/stats/StatsForm" 

export default function TestPage3() { 
  return (
    <div>
      <StatsForm></StatsForm>
      <style global jsx="true">{`

                body { 
                    font-family: "Helvetica Neue", "Arial";
                    width: 100vw;   
                    margin: 0px;
                    padding: 0px;
                }    
            `}</style>
    </div>
  )
} 
