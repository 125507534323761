const COLORS = {
    "white": '#ffffff',
    "extra_light_gray": '#f2f2f2',
    "myExtraLightGrayColor": '#ededf2',
    "light_gray": '#efeff4',
    "myLightGrayColor": '#efeff4',
    "chart_gray": '#e5e5ea',
    "myGrayColor": '#e5e5ea',
    "myKeyboardGrayColor": '#d1d4d9',
    "myMediumLightGrayColor": '#c8c8c8',
    "myMagnesiumGrayColor": '#b3b3b3',
    "blue_gray": '#b3b3b3',
    "gray": '#aaaaaa',
    "lightgray": '#aaaaaa',
    "darkgray": '#555555',
    "dark_gray": '#555555',
    "blue_gray_2": "#f7f7fa",
    "myDarkGrayColor": '#454545',
    "myClerkieBlack": '#333333',
    "myBlackColor": '#212f37',
    "black": '#000000',
    "chart_black": '#000000',
    "myLightBlueColor2": '#ccefff',
    "blue_2": '#45d4ff',
    "myBlueGreenColor": '#00d9ff',
    "funLightBlue" : '#00d4ff',
    "clerkieColor": '#3dacff',


    "myLightBlueColor": '#4097f5',
    "myDarkBlueColor": '#3399ff',
    "blue": '#3399ff',
    "myBrightPurpleColor2": '#5084f2',
    "blue_3": '#3187c4',
    "myBrightBlueColor": '#335fff',
    "dark_blue": '#2e6dd0',
    "funBlue": '#3f6aff',
    "myNavyBlueColor": '#062439',
    "PurpleBlue": "#23189b",
    "LightPurpleBlue": "#6772e5",
    "WebPurple": "#ab498c",
    "DarkPurple": "#55255e",
    "webLightBlue": "#f3fafd",

    "lightMyRedColor": '#ffd9e3',
    "myDarkRedColor": '#ff3c86',
    "myRedColor": '#ff1268',
    "red": '#ff1268',
    "clerkie_pink": '#ff1268',
    "funSalmon": '#ff4a4a',
    "clerkie_red": '#ff0000',
    "chart_red": '#e82459',
    "myBurgundyColor": '#c12552',
    "burgundy": '#c12552',
    "funPink": '#fa60c4',
    "myPastelColor": '#ff98fb',

    "chart_blue": '#00c0ce',
    "myTurquoiseColor": '#73d7d7',
    "myCyanColor": '#3ddae5',
    "funCyan": '#41ebc1',
    "cyan": '#00ffff',

    "lightFunGreen": '#d2f4d7',
    "lime": '#9bdc69',
    "myLightGreenColor": '#73dc69',
    "myGreenColor": '#5ecf25',
    "green": '#5ecf25',
    "funGreen": '#00d933',
    "green_2": '#28bf94',
    "myDarkGreenColor": '#00bb50',
    "dark_green": '#6a961f',

    "yellow": '#f5c700',
    "graphStartColor": '#fae916',
    "myGoldColor": '#ffdf00',
    "myMutedOrangeColor": '#ffdb3a',
    "myYellowColor": '#f5c345',
    "myOrangeColor": '#f2bf36',

    "myBrightOrangeColor": '#fe7301',
    "myBrightYellowColor": '#ff4929',
    "graphEndColor": '#fc4f08',
    "funOrange": '#ff5e00',
    "orange": '#ff6600',
    "brown": '#b36435',

    "myLightPurpleColor": '#aaaff7',
    "light_purple": '#aaaff7',
    "myBrightPurpleColor": '#b37fff',
    "dark_purple": '#b37fff',
    "blue_4": '#b816ff',
    "funPurple": '#b141ff',
    "purple": '#800080',
    "magenta": '#ff00ff',

    "a0b1c2": '#3dacff',
    "a0b1c3": '#000000',
    "a0b1c4": '#5ecf25',
    "a0b1c5": '#ff1268',
    "a0b1c6": '#fe7301',
    "a0b1c7": '#f5c345',
    "a0b1c8": '#00ff00',

    "clear": 'transparent',
    "offWhiteColor": '#fafafa',
    "splashColor": '#242424'
  }
  
export default COLORS;